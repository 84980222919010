import {
	MeasurementEvent,
	PushMeasurementOptions,
	faro,
} from "@grafana/faro-web-sdk"

import {
	getIsUserBondsInvestor,
	getIsUserFDInvestor,
	getUser,
	getUserId,
	getUserMandateStatus,
} from "../auth"
import { assertBrowserEnv, isBrowser, isWebview } from "../browser"

declare global {
	interface Window {
		gtmDataLayer: any[]
		Android: any
		webkit: any
	}
}

export const trackEventOnGTM = (
	eventName: string,
	eventAttributes: any,
): void => {
	try {
		assertBrowserEnv()

		const modifiedEventAttributes = {
			userId: getUserId(),
			...eventAttributes,
		}

		if (isWebview()) {
			modifiedEventAttributes.userAgent = "android"
		}

		window.gtmDataLayer.push({
			event: eventName,
			...modifiedEventAttributes,
		})
	} catch (error) {
		console.error("Error while tracking event on GTM", error)
	}
}

export const getDefaultEventAttributes = (): Record<string, any> => {
	const isBrowserWindow = isBrowser()
	

	const user = getUser()
	return Object.fromEntries(
		Object.entries({
			"User ID": user.userId,
			IS_FD_INVESTOR: getIsUserFDInvestor(),
			IS_BOND_INVESTOR: getIsUserBondsInvestor(),
			"User Agent": (isBrowserWindow && window.navigator.userAgent) || "",
			IS_FD_WAITLISTER: !!user.isUserFDWaitlister,
			CURRENT_URL: isBrowserWindow ? window.location.href : "",
			"SEBI KYC Status": user?.kycStatusV2,
			"Mandate status": getUserMandateStatus(),
		}),
	)
}

declare global {
	interface Window {
		mixpanel: any
	}
}

export const handleMixpanelEvent = (
	eventName: string,
	attributes: Record<string, any> = {},
): void => {
	const isBrowserWindow = isBrowser()

	try {
		if (isBrowserWindow && window.mixpanel) {
			window.mixpanel.track(eventName, {
				...attributes,
				...getDefaultEventAttributes(),
			})

			if (eventName === "Page View") {
				window.mixpanel.time_event("Page Exit")
			}
		}
	} catch (e) {
		console.error("Error while tracking event on Mixpanel", eventName, e)
	}
}

export const pushEventWithFaro = (eventName: string, data: any) => {
	// invariant(faro.api, "Faro API is not available")
	if (!faro.api) return

	faro.api.pushEvent(eventName, data)
}

export const pushMeasurementWithFaro = (
	payload: MeasurementEvent,
	options?: PushMeasurementOptions,
) => {
	// invariant(faro.api, "Faro API is not available")
	if (!faro.api) return

	faro.api.pushMeasurement(payload, options)
}

 export const getMessageHashHex = async (message:string) => {
	const encoder = new TextEncoder()
	const data = encoder.encode(message)
	const hash = await window.crypto.subtle.digest("SHA-256", data)
	
	// Convert buffer to byte array
	const hashArray = Array.from(new Uint8Array(hash))
	
	// Convert bytes to hex string
	const hashHex = hashArray
	  .map(b => b.toString(16).padStart(2, "0"))
	  .join("")
	  
	return hashHex
  }

type ExtractFbpClickId = (fbpCookie: string | null) => string | null
type GetFbpCookie = () => string | null
type GetFacebookClickId = () => string | null


/**
 * Extracts the click ID from a Facebook _fbp cookie value
 * @param {string | null} fbpCookie - The _fbp cookie value
 * @returns {string | null} The click ID or null if invalid format
 */
const extractFbpClickId: ExtractFbpClickId = (fbpCookie) => {
    // _fbp cookie format: fb.1.timestamp.clickId
    if (!fbpCookie) return null
    
    try {
        // Split the cookie value by dots
        const parts: string[] = fbpCookie.split(".")
        
        // Check if the cookie has the correct format
        if (parts.length !== 4 || parts[0] !== "fb") {
            return null
        }
        
        // The click ID is the last part
        return parts[3]
    } catch (error) {
        return null
    }
}

/**
 * Helper function to get the _fbp cookie value
 * @returns {string | null} The _fbp cookie value or null if not found
 */
const getFbpCookie: GetFbpCookie = () => {
    const cookies: string[] = document.cookie.split(";")

	return cookies
        .map(cookie => cookie.trim())
        .find(cookie => cookie.startsWith("_fbp="))
        ?.substring(5) ?? null
}

/**
 * Gets the Facebook click ID from the _fbp cookie
 * @returns {string | null} The click ID or null if not found/invalid
 */
export const getFacebookClickId: GetFacebookClickId = () => {
    const fbpCookie: string | null = getFbpCookie()
    return extractFbpClickId(fbpCookie)
}

